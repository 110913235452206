/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv(baseApi) {
    return {
        route: {
            baseRoute: '/', // Fixes issue with Github Pages
        },
        api: {
            api: `${baseApi}/api/:path`,
            auth: `${baseApi}/auth/login`,
            validateToken: `${baseApi}/auth/validate`,
            recipeSearch: `${baseApi}/search/:endpoint`,
            image: `${baseApi}/api/image/:attribute`,
            license_recipes: `${baseApi}/api/license/recipes`,
            update_recipe: `${baseApi}/api/recipe/:attribute`,
            cast: `${baseApi}/shows/:showId/cast`,
            shows: `${baseApi}/shows/:showId`,
            errorExample: 'https://httpstat.us/520',
            preparedFoods: `${baseApi}/prepared-foods/:preparedFoodId`,
            organizations: `${baseApi}/api/vcp/organizations`,
            saga: `${baseApi}/api/sagas/:saga`,
            deleteImage: `${baseApi}/api/image/delete`,
            recipeStateService: `${baseApi}/api/recipe-state-service/:state`,
            rocheProjection: `${baseApi}/api/roche`,
            buschesApi: `${baseApi}/api/busches`,
            wakerfernApi: `${baseApi}/api/wakefern`,
            pcApi: `${baseApi}/api/price-chopper`,
            stateLogs: `${baseApi}/api/recipe-state-log`,
            users: `${baseApi}/api/user`,
            tertiaryCollections: `${baseApi}/api/tertiary-collections/:endpoint`,
            dole: `${baseApi}/api/dole/:endpoint`,
            busches: `${baseApi}/api/busches/:endpoint`,
        },
        buschesProjection: {
            exportRecipe: `${baseApi}/api/busches/recipe/:id`,
        },
        isProduction: true,
        isDevelopment: false,
        isTesting: false,
    };
}
